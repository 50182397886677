.giftWrapper video {
  height: calc(100vh - 300px);
  margin: auto;
  object-fit: cover;
}

.giftWrapper {
  text-align: center;
  height: calc(100vh - 300px);
  margin: auto;
}

.wrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  color: #fff;
  background-size: cover;
  position: relative;
}

.show {
  display: block;
  margin: auto;
}

.lightWrapper {
  position: absolute;
  right: 10%;
}

.hide {
  display: none
}

@media only screen and (max-width: 1100px) {
  .wrapper {
    height: auto;
  }
  
  .giftWrapper video {
    width: inherit;
  }
}

@media only screen and (max-width: 400px) {
  .giftWrapper {
    width: 100%;
  }
}