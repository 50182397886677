.winnerWrapper {
  position: relative;
  overflow: hidden;
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  color: #fff;
  padding-top: 120px;
}

.opening {
  text-align: center;
  margin: 4rem auto;
  max-width: 700px;
}

.twoRow {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
}

.winnerUnit {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: .3rem auto;
}

.winnerUnit img {
  max-width: 267px;

}

.opening p {
  margin: 5px auto;
}

.formWrapper {
  max-width: 1200px;
  margin: 2rem auto;
  color: #fff;
}

.formWrapper li {
  text-align: left;
  margin: 1rem 0.3rem;
  list-style: none;
}

.memberWrapper {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-evenly;
}

.memberWrapper li {
    width: 400px;
}

.winnerListWrapper li i,
.wrapperRight li i {
  margin-right: 0.3rem;
  color: #a68654;
}

.winnerListWrapper {
  display: flex;
  justify-content: space-between;
  color: #e2d4bd;
}

.message {
  font-size: 1rem;
  font-weight: 400;
  color: #e2d4bd;
  line-height: 25px;
}

.specialWrapper {
  max-width: 350px;
}

.specialWrapper img {
  max-width: 100%;
}

@media only screen and (max-width: 1300px) {
  .formWrapper {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {

  .winnerListWrapper {
    display: block;
    /* width: 80%; */
    margin: auto;
  }
  .messageWrapper {
    margin: auto;
  }
  .widthControler {
    width: 90%;
    margin: auto;
  }
  .winnerImage {
    margin: 100px auto 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .winnerImage img {
    max-width: 90%;
    max-height: 400px;
  }
}
