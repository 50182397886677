.judgeWrapper {
  text-align: center;
}

.judgeHead {
  padding: 1em;
  margin: 0;
}
.judgeCardWrapper {
  margin: 0;
  display: flex;
  justify-content: center;
  color: #fff;
  height: 90%;
  white-space: pre;
}
.judgeCard {
  width: 25%;
  background-color: #000;
  color: #fff;
  background-color: #0f1422;
  padding: 0 2em 2em 2em;
  min-height: fit-content;
  background: linear-gradient(180deg, rgba(218, 240, 252, 0) 20%, #29355f 58%, #111627 100%);
  border: 4px solid;
  border-top: 0;
  border-image-slice: 1;
  box-sizing: border-box;
  border-image-source: linear-gradient(180deg, rgba(218, 240, 252, 0.005) 0%, #866c4b 100%);
}

.judgeImg {
  display: block;
  margin: 2em auto;
  width: 70%;
}

.judgeTxtWrapper {
  margin-top: 1em;
  color: #fff;
  text-align: center;
}

.judgeTxtContentWrapper p {
  margin-top: 2em;
  color: #b7b1ac;
  white-space: pre-wrap;
}

.imgWrapper {
  text-align: center;
  white-space: pre-wrap;
  
}



@media only screen and (max-width: 1500px) {
  .judgeCard {
  }
}

@media only screen and (max-width: 1100px) {
  .judgeCardWrapper {
  }
  .judgeCard {
    margin: 1em auto;
    width: 100%;
  }
  .imgWrapper {
    width: 100%;
    /* display: flex; */
    justify-content: flex-start;
    align-items: center;
  }
  .imgWrapper h2 {
    margin: 0;
  }
  .imgWrapper img {
    margin: 2em auto;
  }
}
