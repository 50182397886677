html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  letter-spacing: 2px;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
  background-color: #000;
}


main {
  position: relative;
  overflow: hidden;
}

h1 {
  font-size: 34px;
  font-weight: bolder;
}

h2 {
  font-size: 24px;
  font-weight: bolder;
  color: #f1eae5;
}

p,
a,
input,
button {
  font-size: 18px;
  color: #e2d4bd;
  font-weight: 600;
  line-height: 1.8em;
}

input,
.form-group input {
  background-color: #181818;
  color: white;
  border: 1px solid #6b5941;
}

.widthController {
  max-width: 1060px;
  margin: auto;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.hidden {
  display: none;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 3em 0;
  height: 100%;
  position: relative;
}

.oneBlock {
  height: 100vh;
}

.bigHeader {
  text-align: center;
  color: #deac5e;
}
.bigHeader h1 img {
  width: 50px;
}

.back {
  right: -8%;
  position: fixed;
  bottom: 30px;
  z-index: 10;
}

.back p {
  position: absolute;
  top: 15%;
  left: 4%;
}

.back img {
  width: 50%;
}

.subtitle {
  margin: 3em 0 1em 0;
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.buffer {
  height: 30px;
}

.bannerWrapper {
  position: relative;
}

.banner {
  overflow: hidden;
  height: 714px;
  background-image: url("./public/assets/images/banner_01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner:after {
  content: "";
  width: 100%;
  height: 160px;
  background-image: url("./public/assets/images/bottom.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: -7.2%;
  left: 0;
  z-index: 1;
}

.line {
  width: 100%;
}

.bg0 {
  background-image: url("./public/assets/images/coin.png") !important;
}

.bg1 {
  background-image: url("./public/assets/images/coin_1.png") !important;
}
.bg2 {
  background-image: url("./public/assets/images/coin_2.png") !important;
}
.bg3 {
  background-image: url("./public/assets/images/coin_3.png") !important;
}

.ad-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-width,
.fixed-width-nav {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  padding: 0 24px;
  height: 100%;
  align-items: center;
}

.navbar {
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  letter-spacing: 3px;
  padding: 0;
  height: inherit;
  transition: all 0.3s linear;
}

.navBarBlack {
  background-color: #110e0a;
}

.nav-lists {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.nav-open {
  display: none;
}

.flex-end {
  justify-content: flex-end;
  align-items: center;
}

.banner-wrapper {
  height: 100vh;
}

.navbar-menu {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  list-style: none;
  height: 90px;
  width: 1060px;
}

.navbar-menu li a {
  color: #e2d4bd;
}

.navbar-menu li a:hover,
.navbar-menu li a:active,
.navbar-menu li a.active,
.reserve a:hover,
.reserve a:active,
.reserve a.active {
  color: #f1eae5;
}

.App-header {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 999;
}

.activatedTitle {
  color: #a68654;
}

.navbar-menu li a.active:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.mucisBg0 {
  background-image: url('./public/assets/images/MUSIC_01.jpg');
}

.mucisBg1 {
  background-image: url('./public/assets/images/MUSIC_02.jpg');
}
.mucisBg2 {
  background-image: url('./public/assets/images/MUSIC_03.jpg');
}
.mucisBg3 {
  background-image: url('./public/assets/images/MUSIC_04.jpg');
}
.mucisBg4 {
  background-image: url('./public/assets/images/MUSIC_05.jpg');
}
.mucisBg5 {
  background-image: url('./public/assets/images/MUSIC_06.jpg');
}

.hidden {
  display: none !important;
  opacity: 0;
}

.display {
  display: block !important;
  opacity: 1;
}


.gallerybg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gallerybg1 {
  background-image: url("./public/assets/images/members/stripe/1.png");
}
.gallerybg2 {
  background-image: url("./public/assets/images/members/stripe/2.png");
}
.gallerybg3 {
  background-image: url("./public/assets/images/members/stripe/3.png");
}
.gallerybg4 {
  background-image: url("./public/assets/images/members/stripe/4.png");
}
.gallerybg5 {
  background-image: url("./public/assets/images/members/stripe/5.png");
}
.gallerybg6 {
  background-image: url("./public/assets/images/members/stripe/6.png");
}
.gallerybg7 {
  background-image: url("./public/assets/images/members/stripe/7.png");
}
.gallerybg8 {
  background-image: url("./public/assets/images/members/stripe/8.png");
}

.gallerybgUnit {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gallerybgUnit0 {
  background-image: url("./public/assets/images/members/0.png");
}

.gallerybgUnit1 {
  background-image: url("./public/assets/images/members/1.png");
}

.gallerybgUnit2 {
  background-image: url("./public/assets/images/members/2.png");
}

.gallerybgUnit3 {
  background-image: url("./public/assets/images/members/3.png");
}

.gallerybgUnit4 {
  background-image: url("./public/assets/images/members/4.png");
}

.gallerybgUnit5 {
  background-image: url("./public/assets/images/members/5.png");
}

.gallerybgUnit6 {
  background-image: url("./public/assets/images/members/6.png");
}

.gallerybgUnit7 {
  background-image: url("./public/assets/images/members/7.png");
}

.gallerybgUnit8 {
  background-image: url("./public/assets/images/members/8.png");
}

.gallerybgUnit9 {
  background-image: url("./public/assets/images/members/9.png");
}

.gallerybgUnit10 {
  background-image: url("./public/assets/images/members/10.png");
}

.gallerybgUnit11 {
  background-image: url("./public/assets/images/members/11.png");
}

.gallerybgUnit12 {
  background-image: url("./public/assets/images/members/12.png");
}

.gallerybgUnit13 {
  background-image: url("./public/assets/images/members/13.png");
}

.gallerybgUnit14 {
  background-image: url("./public/assets/images/members/14.png");
}

.gallerybgUnit15 {
  background-image: url("./public/assets/images/members/15.png");
}

.gallerybgUnit16 {
  background-image: url("./public/assets/images/members/16.png");
}

.gallerybgUnit17 {
  background-image: url("./public/assets/images/members/17.png");
}

.gallerybgUnit18 {
  background-image: url("./public/assets/images/members/18.png");
}

.gallerybgUnit19 {
  background-image: url("./public/assets/images/members/19.png");
}

.gallerybgUnit20 {
  background-image: url("./public/assets/images/members/20.png");
}

.gallerybgUnit21 {
  background-image: url("./public/assets/images/members/21.png");
}

.gallerybgUnit22 {
  background-image: url("./public/assets/images/members/22.png");
}

.gallerybgUnit23 {
  background-image: url("./public/assets/images/members/23.png");
}

.gallerybgUnit24 {
  background-image: url("./public/assets/images/members/24.png");
}

.gallerybgUnit25 {
  background-image: url("./public/assets/images/members/25.png");
}

.gallerybgUnit26 {
  background-image: url("./public/assets/images/members/26.png");
}

.gallerybgUnit27 {
  background-image: url("./public/assets/images/members/27.png");
}

.gallerybgUnit28 {
  background-image: url("./public/assets/images/members/28.png");
}

.gallerybgUnit29 {
  background-image: url("./public/assets/images/members/29.png");
}

.gallerybgUnit30 {
  background-image: url("./public/assets/images/members/30.png");
}

.gallerybgUnit31 {
  background-image: url("./public/assets/images/members/31.png");
}

.gallerybgUnit32 {
  background-image: url("./public/assets/images/members/32.png");
}

.gallerybgUnit33 {
  background-image: url("./public/assets/images/members/33.png");
}

.gallerybgUnit34 {
  background-image: url("./public/assets/images/members/34.png");
}

.gallerybgUnit35 {
  background-image: url("./public/assets/images/members/35.png");
}

.gallerybgUnit36 {
  background-image: url("./public/assets/images/members/36.png");
}

.gallerybgUnit37 {
  background-image: url("./public/assets/images/members/37.png");
}

.gallerybgUnit38 {
  background-image: url("./public/assets/images/members/38.png");
}

.gallerybgUnit39 {
  background-image: url("./public/assets/images/members/39.png");
}

.gallerybgUnit40 {
  background-image: url("./public/assets/images/members/40.png");
}

.gallerybgUnit41 {
  background-image: url("./public/assets/images/members/41.png");
}

.gallerybgUnit42 {
  background-image: url("./public/assets/images/members/42.png");
}

.gallerybgUnit43 {
  background-image: url("./public/assets/images/members/43.png");
}

.gallerybgUnit44 {
  background-image: url("./public/assets/images/members/44.png");
}

.gallerybgUnit45 {
  background-image: url("./public/assets/images/members/45.png");
}

.gallerybgUnit46 {
  background-image: url("./public/assets/images/members/46.png");
}

.gallerybgUnit47 {
  background-image: url("./public/assets/images/members/47.png");
}

.gallerybgUnit48 {
  background-image: url("./public/assets/images/members/48.png");
}

.gallerybgUnit49 {
  background-image: url("./public/assets/images/members/49.png");
}

.gallerybgUnit50 {
  background-image: url("./public/assets/images/members/50.png");
}

.gallerybgUnit51 {
  background-image: url("./public/assets/images/members/51.png");
}

.gallerybgUnit52 {
  background-image: url("./public/assets/images/members/52.png");
}

.gallerybgUnit53 {
  background-image: url("./public/assets/images/members/53.png");
}

.gallerybgUnit54 {
  background-image: url("./public/assets/images/members/54.png");
}

.gallerybgUnit55 {
  background-image: url("./public/assets/images/members/55.png");
}

.gallerybgUnit56 {
  background-image: url("./public/assets/images/members/56.png");
}

.gallerybgUnit57 {
  background-image: url("./public/assets/images/members/57.png");
}

.gallerybgUnit58 {
  background-image: url("./public/assets/images/members/58.png");
}

.gallerybgUnit59 {
  background-image: url("./public/assets/images/members/59.png");
}

.gallerybgUnit60 {
  background-image: url("./public/assets/images/members/60.png");
}

.gallerybgUnit61 {
  background-image: url("./public/assets/images/members/61.png");
}

.gallerybgUnit62 {
  background-image: url("./public/assets/images/members/62.png");
}

.gallerybgUnit63 {
  background-image: url("./public/assets/images/members/63.png");
}

.gallerybgUnit64 {
  background-image: url("./public/assets/images/members/64.png");
}

.gallerybgUnit65 {
  background-image: url("./public/assets/images/members/65.png");
}

.gallerybgUnit66 {
  background-image: url("./public/assets/images/members/66.png");
}

.gallerybgUnit67 {
  background-image: url("./public/assets/images/members/67.png");
}

.gallerybgUnit68 {
  background-image: url("./public/assets/images/members/68.png");
}

.gallerybgUnit69 {
  background-image: url("./public/assets/images/members/69.png");
}

.gallerybgUnit70 {
  background-image: url("./public/assets/images/members/70.png");
}

.gallerybgUnit71 {
  background-image: url("./public/assets/images/members/71.png");
}

.gallerybgUnit72 {
  background-image: url("./public/assets/images/members/72.png");
}

.gallerybgUnit73 {
  background-image: url("./public/assets/images/members/73.png");
}

.gallerybgUnit74 {
  background-image: url("./public/assets/images/members/74.png");
}

.gallerybgUnit75 {
  background-image: url("./public/assets/images/members/75.png");
}

.gallerybgUnit76 {
  background-image: url("./public/assets/images/members/76.png");
}

.gallerybgUnit77 {
  background-image: url("./public/assets/images/members/77.png");
}

.gallerybgUnit78 {
  background-image: url("./public/assets/images/members/78.png");
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-0pky {
  border-color: inherit;
  text-align: center;
  vertical-align: center;
  font-size: 20px;
}

.note {
  height: 150px;
  width: 150px;
  background-size: contain;
  margin: 2px 0;
  position: absolute;
  padding: 0.5em;
  color: #000;
}

div.note > .noteTxt {
  padding: 1em;
}

div.note p {
  color: #000;
}

.glyphicon-plus {
  position: relative;
}

.select-wrapper {
  background-color: #000;
  margin-left: 1em;
}

.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #fff;
  line-height: 1.3;
  padding: 0.1em 1.4em 0.1em 0.8em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #110e0a 0%, #110e0a 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css option {
  color: #000;
}

.rulesWrapper {
  width: 80%;
  margin: auto;
  transition: all 0.5s linear;
  opacity: 1;
  transform-origin: top;
  display: block;
}

.scheduleBlock:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(100% - 32px);
  width: 3px;
  background: #2d1a5b;
}
.scheduleBlock::after {
  display: block;
  height: 32px;
  width: 32px;
  background-image: url("./public/assets/icons/button.png");
}

.disable {
  pointer-events: none;
  cursor: default;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 10px;
  margin: 80px 0 5px 0;
}
::-webkit-scrollbar-thumb {
  background: #a68654;
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.buynowbutton {
  position: fixed;
  height: 31px;
  width: 62px;
  bottom: 100px;
  right: 100px;
  z-index: 10;
  background-image: url("./public/assets/images/icon_top_02.png");
}

.buynowbutton:hover {
  height: 41px;
  width: 62px;
  background-image: url("./public/assets/images/icon_top_01.png");
}

.buynowbutton a {
  width: 60px;
  height: 50px;
  margin: 0;
  display: block;
}

.yellow {
  color: #a69654;
  margin: 0;
}

@keyframes heartBeat {
  0%,
  20%,
  50%,
  80%,
  100% {
    font-size: 1.2em;
  }
  40% {
    font-size: 1.8em;
  }
  60% {
    font-size: 1.4em;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  p,
  a {
    font-size: 14px;
  }

  .banner {
    height: 700px;
  }

  .select-css {
    font-size: 14px;
  }

  .wrapper {
    width: 90%;
  }
  .mobile {
    display: block;
  }

  .nav-lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: flex-start;
  }
  .navbar-menu.active {
    left: 0;
  }
}

.fas {
  line-height: 0 !important;
}

.fa-undo:before {
  font-size: 0.65em;
}

@media only screen and (max-width: 1100px) {
  h1 .fixed-width-nav {
    position: relative;
    align-items: center;
    transition: all linear 0.5s;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .nav-lists {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: flex-start;
    background: linear-gradient(180deg, rgba(36, 88, 166, 0.1474964985994398) 0%, 110a 15%, rgba(28, 46, 83, 0.923406862745098) 30%, #110e0a 75%);
    background-color: #110e0a;
  }
  .navbar {
    background-color: #110e0a;
  }

  .nav-lists li {
    width: 100%;
    text-align: start;
  }

  .navbar-logo-m {
    height: 60px;
    display: none;
  }

  .navbar-menu li a:active,
  .navbar-menu li a.active {
    color: #bfbfbf;
  }

  .navbar-menu li a:active:after,
  .navbar-menu li a.active:after {
    display: none;
  }

  .navbar-menu {
    display: block;
    width: 100%;
    position: fixed;
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    top: 90px;
    left: 100%;
    height: 100vh;
    transition: all ease-in-out 0.5s;
  }
  .navbar-menu li {
    font-size: 1em;
    margin: 1em 0;
    border-bottom: 1px solid #f1eae5;
  }
  .navbar-menu li a {
    font-size: 14px;
  }
  .mobile {
    display: block;
  }
  .banner:after {
    bottom: -4%;
    height: 90px;
  }
  .nav-open {
    display: block;
    border: none;
    height: 30px;
    position: relative;
    width: 30px;
    background-color: #000;
    margin-left: 15%;
  }
  .nav-open:focus {
    border: none;
  }

  .nav-open .inner,
  .nav-open .inner::before,
  .nav-open .inner::after {
    background-color: white;
    content: "";
    display: block;
    height: 2px;
    width: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .nav-open .inner {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .nav-open .inner::before {
    margin-top: -8px;
    position: absolute;
  }

  .nav-open .inner::after {
    margin-top: 8px;
    position: absolute;
  }

  .nav-open.nav-close .inner {
    background-color: transparent;
    left: 54%;
  }

  .nav-open.nav-close .inner::before,
  .nav-open.nav-close .inner::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    width: 23px;
  }

  .nav-open.nav-close .inner::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .effect1 {
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 7px #fff, 0 0 0 9px #000, 0 0 0 15px #fff, 0 0 0 17px #000;
    box-shadow: 0 0 0 7px #fff, 0 0 0 9px #000, 0 0 0 15px #fff, 0 0 0 17px #000;
  }
}

.subtitle_p1 {
  background-color: #181818;
  border-radius: 5px;
  color: #a68654;
  width: fit-content;
  padding: 0.5em 1em;
  border: 1px solid #6b5941;
  margin-top: 2em;
}

@media only screen and (max-width: 1300px) {
  .subtitle {
    text-align: left;
    width: 90%;
    margin: 2em auto;
  }

  .gallerybg1 {
    background-image: url("./public/assets/images/members/stripe/1m.png");
  }
  .gallerybg2 {
    background-image: url("./public/assets/images/members/stripe/2m.png");
  }
  .gallerybg3 {
    background-image: url("./public/assets/images/members/stripe/3m.png");
  }
  .gallerybg4 {
    background-image: url("./public/assets/images/members/stripe/4m.png");
  }
  .gallerybg5 {
    background-image: url("./public/assets/images/members/stripe/5m.png");
  }
  .gallerybg6 {
    background-image: url("./public/assets/images/members/stripe/6m.png");
  }
  .gallerybg7 {
    background-image: url("./public/assets/images/members/stripe/7m.png");
  }
  .gallerybg8 {
    background-image: url("./public/assets/images/members/stripe/8m.png");
  }
}

@media only screen and (max-width: 700px) {
  .buynowbutton {
    bottom: 50px;
    right: 20px;
  }
  .center {
    text-align: center;
  }
  .bigHeader img {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .navBarBlack {
    background-color: #110e0a;
  }

  .navBarNone {
    background-color: #110e0a;
  }
}

@media only screen and (max-width: 1100px) {
  .banner {
    background-image: url("./public/assets/images/banner_02.jpg");
    background-position: center;
  }
  .widthController {
    width: 100%;
    margin: auto;
    padding: 0 24px;
  }
}
