.bannerTxtWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
}

.bannerTxt {
  position: absolute;
  /* background-image: url("../public/assets/images/text_banner.png"); */
  /* max-width: 1200px; */
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 1200px;
  margin: auto;
}

.bannerInner {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 40px;
}

.buttonWrapper {
  width: 250px;
  margin: 10px auto;
  position: relative;
  bottom: 150px;
  z-index: 10;
}

/* .bug {
  position: absolute;
  top: calc(100vh - 110px);
  left: 75%;
  z-index: 3;
} */

.flyleft {
  float: left;
  width: 20%;
}

.flyright {
  float: right;
}

@media only screen and (max-width: 1500px) {
  .bannerTxt {
  }
}

@media only screen and (max-width: 700px) {
  .bannerTxt {
  }
}

@media only screen and (max-width: 1100px) {
  .bug {
    display: none;
  }
  .bannerTxt {
    justify-content: center;
  }
  .buttonWrapper {
    margin:5px auto;
  }
  .bannerInner {
    bottom: -30px;
  }
}

@media only screen and (max-width: 350px) {
  .bannerInner img {
    margin-bottom: 80px;
    max-width: 100%;
  }

}
