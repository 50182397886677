.pageBreak {
  margin: 0 auto;
  text-align: center;
  transform: rotate(180deg);
  background-size: contain;
  background-size: 100% 40px;
}

.pageBreak img {
}

.pageBreak1 {
  max-width: 960px;
  height: 40px;
  background-image: url('../public/assets/images/pb_01.png');
}

.pageBreak2 {
  max-width: 960px;
  background-image: url('../public/assets/images/pb_02.png');
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 900px) {
  .pageBreak {
    background-position: center;
    background-size: auto;

  }
}
