.coin {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 50px auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /* -webkit-animation: rotate3d 8s linear infinite;
          animation: rotate3d 8s linear infinite; */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.coinFront {
  position: absolute;
  width: 150px;
  height: 150px;
  overflow: hidden;
  background-image: url("../public/assets/images/coin.png");
  animation: float;
  background-repeat: no-repeat;
  animation: float 2s infinite ease-in-out;
}


@keyframes float {
  0% {
    top: 0;
  }

  25% {
    top: 10px;
  }
  50% {
    top: 40px;
  }
  70% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .coin {
    width: 50px;
    height: 50px;
    left: 35%
  }

  .coin::after {
    top: 48%;
  }
  .coinFront {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  }
}
