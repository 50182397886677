.wrapper {
    background-image: url('../public/assets/images/bg/p2_bg_new.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

header:after,
#timeline .timelineItem:after,
header:before,
#timeline .timelineItem:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.timelineContainer {
  max-width: 1200px;
  margin: 0 auto;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

#timeline .timelineItem {
  margin-bottom: 10px;
  position: relative;
  transition: all 0.5s linear;
}

#timeline .timelineItem .timelineIcon {
  background: #181818;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -6px;
  border-radius: 50%;
  border: 2px solid #6b5941
}



#timeline .timelineItem .timelineContent {
  width: 45%;
  background: #fff;
  padding: 20px;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #0f1422;
  color: #fff;
  transition: all 0.5s linear;
  z-index: 5;
  border: 2px solid #6b5941
}
.timeline:before {
  content: "";
  width: 3px;
  height: 85%;
  background: #6b5941;
  left: 50%;
  top: 0;
  position: absolute;
}

#timeline .timelineItem .timelineContent h2 {
  padding: 15px;
  background: #0f1422;
  color: #fff;
  margin: -20px -20px 0 -20px;
  font-weight: 300;
  border-radius: 3px 3px 0 0;
}


#timeline .timelineItem .timelineContent p {
  padding: 15px 15px 0 0;
}

#timeline .timelineItem .timelineContent:before {
  content: "";
  position: absolute;
  left: 45%;
  top: 5px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 20px solid #0f1422;
}

#timeline .timelineItem .timelineContent.right {
  float: right;
}

#timeline .timelineItem .timelineContent.right:before {
  content: "";
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 20px solid #0f1422;
}

.blank {
  white-space: pre-wrap;
}



@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }
  #timeline:before {
    left: 0;
  }
  #timeline .timelineItem .timelineContent {
    width: 90%;
    float: right;
  }
  
  #timeline .timelineItem .timelineIcon {
    left: 0;
  }
}
/*# sourceMappingURL=timeline.css.map */
