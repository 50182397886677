.limitProductsWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  position: relative;
}

.modalWrapper {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.hidden {
  display: none;
}

.buttonFlexWrapper {
  display: flex;
  margin: 56px auto 163px auto;
  justify-content: space-evenly;
}

.buttonWrapper {
  width: 250px;
}

.buttonWrapperBigProduct {
  width: 250px;
  margin-top: 56px;
}

.carouselWrapper {
  margin-right: 2rem;
  min-width: 300px;
}

.carouselWrapper img {
  max-width: 100%;
}

.headWrapper {
  padding-top: 93px;
  margin-bottom: 56px;
}

.musicLower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonOutterWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.buttonWrapper1 {
  width: 100%;
}

.bigProductWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: auto;
  padding-bottom: 163px;
}

.bigProductTxtWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 35px;
}

.bigProductTxtWrapper h1 {
  color: #deac5e;
}

.bigProductImgWrapper {
  max-width: 560px;
  position: relative;
}

.bigProductImgWrapper img {
  /* width: 100%; */
  max-width: 560px;
}

.bigProductImgWrapper p {
  position: absolute;
  right: 0;
  bottom: 5%;
}

.music {
  display: flex;
  margin: 55px auto;
  padding-bottom: 35px;
  border-bottom: 2px solid #a68654;
}

.music h2 {
  color: #deac5e;
}

.musicLowerTxt p {
}
.music h2 {
}

.musicLowerHead h1 {
  color: #deac5e;
}

.musicLowerHead h2 {
  color: #a68654;
}

.animeWrapper {
  width: 100px;
}

.bigProductTxtWrapper > h2 {
  color: #a68654;
}

@keyframes moveX {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -500px;
  }
}

@media only screen and (max-width: 900px) {
  .limitProducts {
  }
  .musicLower {
    width: 100%;
    margin: auto;
  }
  .musicLowerTxt {
    margin-top: 25px;
  }
  .musicLowerHead {
    margin-top: 25px;
  }
  .carouselWrapper {
    width: 100%;
    margin-bottom: 15px;
    min-width: auto;
  }
  .carouselWrapper {
    width: 100%;
  }
  .carouselWrapper img {
    width: 100%;
  }
  .buttonFlexWrapper {
    margin-top: 35px;
    margin-bottom: 90px;
  }
  .buttonWrapper {
    /* width: 250px; */
  }

  .bigProductTxtWrapper {
    margin: auto;
    min-width: auto;
  }

  .music h2 {
  }
  .bigProductWrapper {
    display: block;
    padding-bottom: 0;
  }
  .buttonWrapperBigProduct {
    margin: 35px auto 90px auto;
  }

  .bigProductImgWrapper {
    margin-bottom: 30px;
  }
  .bigProductImgWrapper {
    margin: 0 auto 50px auto;
  }

  .bigProductImgWrapper img {
    width: 100%;
  }
  .bigProductTxtWrapper h1 {
    margin-bottom: 25px;
  }
}

.center {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .music {
    display: block;
    margin: 25px auto 55px auto;
  }
  
  .bigProductImgWrapper p {
    font-size: 12px;
    position: static;
    text-align: center;
  }
  .headWrapper {
    padding-top: 55px;
    margin-bottom: 25px;
  }
  .buttonFlexWrapper {
    display: block;
  }
  .buttonWrapper {
    margin: 30px auto;
  }
}
