.introWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: fit-content;
  padding: 2rem 0;
}

.introWrapper:after {
  content: "";
  width: 100%;
  height: 6px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  z-index: 3;
}

.introCardWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.introCardWrapper > div:nth-child(1),
.introCardWrapper > div:nth-child(2) {
  border-right: none;
}

@media only screen and (max-width: 1500px) {
  .introCardWrapper {
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  .introCardWrapper {
    display: block;
  }
  .introCardWrapper > div:nth-child(1),
  .introCardWrapper > div:nth-child(2) {
    border-right: 4px solid;
  }
}
