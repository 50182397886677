.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.rulesContent {
  color: #000;
}

.lowerWrapper {
  display: flex;
  padding: 1em;
  justify-content: center;
}

.upperImg img {
  margin: auto;
  display: block;
}

.lowerWrapper img {
  border-radius: 50%;
  margin-right: 2em;
  height: 200px;
}

.lowerWrapper ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.boxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voteButton {
  padding: 2px 4px;
}

.button {
  background-image: url("../public/assets/images/button.png");
  width: 440px;
  display: block;
  height: 90px;
  text-align: center;
  align-items: center;
  margin: auto;
}

.button::after {
  content: "馬上報名";
  position: relative;
  top: 30px;
  font-size: 1.5em;
}

.emailAddress {
  border: 1px #fff solid;
  padding: 1em;
  text-align: left;
  max-width: 1200px;
  margin: 4em auto;
  border-radius: 20px;
  text-align: center;
}

.rules {
  position: relative;
  width: 70%;
  padding: 20px 20px;
  box-sizing: border-box;
  margin: 20px auto;
  border-radius: 3;
  z-index: 5;
  text-align: left;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  height: 80%;
}
.rules::after {
  background-image: url("../public/assets/images/bg/vote.png");
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.pic {
  position: relative;
  width: 20%;
  height: 50%;
}

.pic img {
  width: 100%;
}
.selected {
  background-color: rgba(0, 0, 0, 0.7)
}

.selected:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../public/assets/images/stamp.png");
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1100px) {
  .wrapper {
    height: 120vh;
  }
  .lowerWrapper img {
    display: none;
  }
  .rules {
    width: 300px;
  }
  .lowerWrapper {
    width: 80%;
    margin: auto;
  }
  .button {
    max-width: 300px;
    background-size: contain;
    height: 65px;
  }
  .button::after {
    top: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  .pic {
    width: 50%;
    height: 125px;
  }
}
