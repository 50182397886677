:root {
    --text-color-1: #f1eae5;
    --text-color-2: #e2d4bd;
    --text-color-3: #a68654;
    --text-color-4: #deac5e;
    --text-color-5: #110e0a;
  }
  

.buttonWrapper a {
    width: 100%;
    display: block;
    border: #f1eae5 2px solid;
    background-color: var(--text-color-5) ;
    width: fit-content;
    padding: 1em 4em;
}