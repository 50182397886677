.subbannerWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  position: relative;
}

.headWrapper {
  padding-top: 106px;
  padding-bottom: 56px;
}

.subbannerBlockHead {
  white-space: pre-wrap;
}

.subbannerBlock h1 {
  font-size: 3em;
  margin-bottom: 1em;
}

.subbannerBlock h2 {
  font-size: 2em;
}

.buttonwrapper {
  margin-bottom: 8rem;
}

.videoWrapper {
  margin: 0 auto;
  border: 3px solid #deac5e;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.textWrapper {
  margin: 56px auto 163px;
}


@media only screen and (max-width: 1100px) {
  .textWrapper {
    text-align: left;
    margin-bottom: 90px;
    margin-top: 25px;
  }
  .headWrapper {
    padding: 85px 0 25px 0;
  }
}