.sectionWrapper {
  margin: 3em 0;
}

.wrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  color: #fff;
  background-size: cover;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  margin: auto;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 1em;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  text-align: center;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 1.5em;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  text-align: center;
  background: linear-gradient(0deg, #32457b , #293558 , #121828);
  color: #fff;
}

.td_1 {
  background-color: #dce3f8;
  color: #000;
}

.td_2 {
  background-color: #8392bd;
  color: #000;
}

.large {
  font-size: 1.5em !important;
  width: 30%;
}

.judgeWrapper {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100vh;
}


.judgeHead {
  padding: 1em;
  margin: 0;
}
.judgeCardWrapper {
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}
.judgeCard {
  width: 300px;
  margin: 0 0em 1em 0em;
  padding: 1em;
  background-color: #000;
  color: #fff;
}

.judgeImg {
  max-width: 100%;
  border-radius: 50%;
}

.judgeTxtWrapper {
  margin-top: 3em;
}

.judgeCard {
  background-color: transparent;
  max-width: 300px;
  height: 500px;
  perspective: 1000px;
}

.judgeCardInner {
  position: relative;
  width: 100%;
  height: 100%;

  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:focus {
  outline: 0;
}

.judgeCard.active .judgeCardInner {
  transform: rotateY(180deg);
}

.judgeCardFront,
.judgeCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.judgeCardFront {
  background: linear-gradient(to left, #4364f7, #6fb1fc);
  color: black;
  z-index: 2;
}

.judgeCardBack {
  background: linear-gradient(to right, #4364f7, #6fb1fc);
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  padding: 1em;
}


@media only screen and (max-width: 1100px) {
  .judgeWrapper {
    height: auto;
  }
  .judgeCardInner {
    transform: rotateY(180deg);
  }
}

@media only screen and (max-width: 700px) {
  .judgeCardWrapper {
    display: block;
  }
}
