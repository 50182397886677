.button {
  background-color: transparent;
  border: none;
}


.mainContainer {
  height: 448px;
  color: #f1eae5;
  background-color: #110e0a; /* fallback for old browsers */
  border: #deac5e 1px solid;
  box-sizing:border-box;
  font-weight: 700;
}

.mainContainer i {
    font-size: 1.5em;
    color:  #deac5e;
}

.mainContainer img {
  height: 25px;
}

.button i {
  margin: 0;
  cursor: pointer;
}

.playlist {
  
}

.playlist li {
  font-size: 14px;
}

.image {
  visibility: hidden;
}

.content {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 89.6px;
  border-bottom: 1px solid #deac5e;
  padding: 0 2rem;
  box-sizing: border-box;
  font-size: 1.2em;
}

/* .content:hover {
  background-color: #e2d4bd;
} */

.mainContainer > div > div:nth-child(5) > div > .content {
  border-bottom: none
}

.dashboard {
  padding:2rem 4rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-top: #a68654 2px solid
}
