:root {
  --text-color-1: #f1eae5;
  --text-color-2: #e2d4bd;
  --text-color-3: #a68654;
  --text-color-4: #deac5e;
  --text-color-5: #110e0a;
}

.mvsWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 0rem 0 10rem 0;
}

.mv {
  max-width: 1000px;
  margin: 4em auto;
}

.mv video {
  width: 100%;
}

.imgWrapper {
  margin: auto;
  max-width: 1200px;
}
.imgWrapper img {
  max-width: 100%;
  display: block;
  margin: auto;
}

.videoWrapper {
  max-width: 1200px;
  margin: 5rem auto;
  border: var(--text-color-3) solid 2px;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.textWrapper {
  max-width: 1000px;
  margin: 5rem auto;
}

.carouselWrapper {
  max-width: 800px;
  margin: auto;
}

.scrollWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 5rem auto;
}

.showroomWrapper {
  margin-bottom: 163px;
}

.scrollWrapper ul {
  margin: -2px;
  margin: 0;
  flex-wrap: nowrap;
  display: flex;
  padding: 0;
  list-style: none;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.scrollWrapper li {
  width: calc(100vh * 80% * 0%);
  height: 100%;
  padding: 2px;
  max-height: 200px;
  overflow: visible;
  margin-right: 12px;
  margin-bottom: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.scrollWrapper li img {
  height: 100%;
  border: 2px solid var(--text-color-3);
}

.scrollWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 5rem auto;
}

.big {
  position: relative;
  margin: auto;
  border: #a68654 1px solid;
  position: relative;
  height: auto;
  width: 100%;
}

.bigImgWrapper {
  max-width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
}

.showroomTxtBg {
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0%;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.big p {
  color: #e2d4bd;
  font-size: 16px;
  z-index: 1;
  display: flex;
  align-items: baseline;
  margin: 0;
  padding: 2rem;
}

.big p::after {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  height: 30%;
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.9) 100%);
  z-index: -1;
}

.big img {
  display: block;
  width: 100%;
  transition: all 0.3s linear;
}

.scrollWrapper ul {
  margin: -2px;
  margin: 0;
  flex-wrap: nowrap;
  display: flex;
  padding: 0;
  list-style: none;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.scrollWrapper li {
  width: calc(100vh * 80% * 0%);
  height: 100%;
  padding: 2px;
  max-height: 200px;
  overflow: visible;
  margin-right: 12px;
  margin-bottom: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.headWrapper {
  padding-top: 93px;
  margin-bottom: 56px;
}

.scrollWrapper li img {
  height: 100%;
  border: 2px solid #a68654;
}

.rulesWrapper {
  margin: 3rem auto;
  color: var(--text-color-2);
  line-height: 1.8em;
}

.inner {
  margin-left: 5px;
}

.rulesWrapper ol {
  margin: 3rem auto;
  list-style: none;
  max-width: 500px;
}
.rulesWrapper li p {
  font-size: 16px;
  margin: 0 3px 0 0;
}

.rulesWrapper li {
  display: flex;
}

.buttonWrapper {
  width: 225px;
  margin: auto;
  color: #a68654;
}
@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 700px) {
  .showroomWrapper {
    margin-bottom: 90px;
  }
  .videoWrapper {
    width: 90%;
  }
  .headWrapper {
    padding-top: 50px;
    margin-bottom: 25px;
  }
  .imgWrapper {
    width: 90%;
  }
  .textWrapper {
    width: 90%;
  }
  .scrollWrapper li {
    width: 40%;
    margin-right: 0.5rem;
  }

  .scrollWrapper li img {
    width: 100%;
  }
  .scrollWrapper {
    width: 100%;
  }
  .mvsWrapper {
    padding: 0;
  }

  .scrollWrapper ul {
    height: 120px;
  }
  .big p {
    font-size: 12px;
    padding: 15px 10px;
  }
}
