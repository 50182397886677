.ruleWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 100px;
}

.ruleWrapper a {
  color: #a68654;
}

.innerWrapper {
  max-width: 1060px;
  margin: 50px auto;
  justify-content: space-between;
  border: 2px solid #a68654;
  padding: 4rem;
  background-color: #181818;
}

.stepsWrapper {
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  margin: 0 auto 163px auto;
}

.lightWrapper {
  position: relative;
  text-align: center;
  width: 25%;
  z-index: 1;
}

.lightWrapper h1 {
  color: #a68654;
  font-size: 4rem;
}

.link {
    color: #a68654;
}

.step {
  width: 70%;
}

.arrow {
  position: absolute;
  top: 25%;
  left: 86%;
}

.buttonWrapper {
    width: 250px;
    margin: 63px auto 93px auto;
}

.stepsWrapper > div:nth-child(4) > .arrow {
  display: none;
}

.ul {
  margin: 1rem auto 1rem 2rem;
  color: #e2d4bd;;
}

.ul li {
  color: #e2d4bd;;
  font-size: 18px;
  font-weight: 600;
}

.yellow {
  color: #a68654;
  margin-top: 2em;
}

.innerWrapper p {
    margin: 0;
    font-size:18px;
}

.titleWrapper {
  margin: 93px 0 55px 0;
}

@media only screen and (max-width: 1100px) {
  .ruleWrapper {
    padding: 0 24px;
  }
  .lightWrapper h1 {
    font-size: 2rem;
    margin: 10px auto;
  }
  .stepsWrapper {
      display: block;
      margin: 90px auto 55px auto;
  }
  .lightWrapper {
      width: 100%;
      margin: 2rem auto;
  }
  .arrow {
    display: none;
  }
  .titleWrapper {
    margin: 55px auto 25px auto;
  }
  .limitProducts {
    display: block;
  }
  .buttonWrapper {
      display: block;
  }
  .innerWrapper {
    padding: 1em;
  }
  .step {
    width: auto;
  }
}
