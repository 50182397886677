.musicWrapper {
  background-image: url("../public/assets/images/bg/p2_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding-bottom: 143px;
}

.headWrapper {
  padding-top: 93px;
  margin-bottom: 56px;
}

.music {
  max-width: 960px;
  margin: 35px auto;
}

.musicLower {
  display: flex;
  margin: 25px auto 0 auto;
  padding-left: 12px;
  color: #e2d4bd;
  font-size: 24px;
}

.musicLeft {
}

.musicLeft,
.musicRight {
  width: 50%;
}

.carouselWrapper {
  width: 80%;
}

.musicLowerTxt div span {
  margin-right: 0.5rem;
}

.musicLower p {
  margin-left: 2rem;
}

.mobile {
  display: none;
}

.animeWrapper {
}

.playWrapper {
  display: flex;
  margin: auto;
  box-sizing: content-box;
  justify-content: center;
}

.imgWrapper {
  box-sizing: content-box;
  border-right: none;
}

.playerWrapper {
  width: 50%;
}

@media only screen and (max-width: 700px) {
  .playerWrapper {
    width: 100%;
    margin: auto;
  }
  .music h2 {
    font-size: 14px;
  }
  .musicWrapper {
    padding-bottom: 90px;
  }
  .imgWrapper {
    margin: auto;
    width: 100%;
  }
  .imgWrapper img {
    width: 100%;
  }
  .playWrapper {
    display: block;
  }
  .musicLowerTxt div {
    font-size: 14px;
  }
  .musicLower {
    display: block;
    margin-top: 15px;
  }
  .musicLeft {
    flex-direction: column;
    height: fit-content;
  }
  .musicLeft,
  .musicRight {
    width: 100%;
  }
  .musicLowerTxt {
    width: 100%;
  }
  .carouselWrapper {
    width: 100%;
  }
  .mobile {
    display: block;
    margin: 2rem 0;
  }
  .musicRight > .musicLowerTxt {
    margin-left: 0;
  }
  .musicLowerTxt div span {
    margin-right: 0;
  }
  .headWrapper {
    padding-top: 50px;
    margin-bottom: 25px;
  }
}
