.wrapper {
  background-image: url("../public/assets/images/bg/p2_bg_3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: fit-content;
  position: relative;
}

.inputArea {
  display: inline-block;
  margin: 0 3em;
}

.counterContainer {
  position: relative;
  text-align: center;
  margin: 2em 0;
}

.formContainer {
  position: relative;
  margin-top: 2em;
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.buttonWrapper a {
  text-decoration: none;
}

.buttonWrapper a:hover {
  color: #fff;
}

.spotlight {
  width: 40%;
  position: absolute;
}

.pillars > img:nth-child(1) {
  left: -10%;
}
.pillars > img:nth-child(3) {
  left: 17.5%;
}
.pillars > img:nth-child(5) {
  left: 42.5%;
}
.pillars > img:nth-child(7) {
  left: 70%;
}

.tree {
  height: 25vh;
}

.errorMessages {
  position: fixed;
  top: 50%;
  left: 35%;
  height: 20%;
  width: 30%;
  background-color: black;
  color: red;
  padding: 2em;
  text-align: center;
}

.emailWarning {
  margin-left: 1em;
  color: red;
}
.hidden {
  display: none;
}

.vote {
  color: #000;
}

.show {
  visibility: visible;
  color: red;
}

.success {
  color: green;
}

.warning {
  color: red;
}

.rabbit {
  width: 130px;
  height: 130px;
  background-image: url(../public/assets/images/walking.png);
  animation: moveX 0.5s steps(6) infinite;
}

.dancing {
  width: 160px;
  height: 160px;
  bottom: 7em;
  background-image: url(../public/assets/images/dancing.png);
  animation: dancing 1s steps(5) infinite;
  left: 40%;
  bottom: 8.5%;
}

@keyframes rotateY {
  to {
    transform: rotateY(360deg);
  }
}

@keyframes moveX {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -798px;
  }
}

@keyframes dancing {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -810px;
  }
}

.outer {
  width: 70%;
  margin: auto;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.pillars {
  display: flex;
  justify-content: space-evenly;
  height: 80%;
  align-items: flex-end;
}

.road {
  width: 100%;
}

.coin {
  position: relative;
  left: -38%;
}

.pillars > div:nth-child(4) > div > div > div > div {
  animation-delay: 0.5s;
}
.pillars > div:nth-child(6) > div > div > div > div {
  animation-delay: 1s;
}

.coin.active:after {
  content: "";
  background-image: url("../public/assets/images/coin_after.png");
  width: 150%;
  height: 160%;
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 8%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.road img {
  width: 100%;
}
.pillar {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.pillars > div:nth-child(4) > img {
  height: 250px;
}

.pillars > div:nth-child(2) > img,
.pillars > div:nth-child(6) > img {
  height: 180px;
}

.pillar img {
  width: 100%;
}
.rabbit {
  position: absolute;
  bottom: 8.5%;
}
.rabbit img {
  width: 100%;
}

.lightWrapper {
  position: absolute;
  top: 250px;
  left: -100px;
}

.buttonL {
  width: 70px;
  height: 200px;
  font-weight: 600;
  position: relative;
  border-radius: 5px;
  border-left: 5px solid #293e7d;
  border-top: 5px solid #546db8;
  border-right: 5px solid #293e7d;
  border-bottom: 5px solid #15254e;
  background: linear-gradient(0deg, rgba(84, 109, 184, 1) 0%, rgba(84, 109, 184, 1) 76%, rgba(176, 191, 238, 1) 99%);
  transition: all 0.1s;
  color: #fff;
}

.buttonL:hover {
  border-radius: 5px;
  border-bottom: 5px solid #546db8;
  border-top: 5px solid #15254e;
  background: linear-gradient(180deg, rgba(84, 109, 184, 1) 0%, rgba(84, 109, 184, 1) 76%, rgba(176, 191, 238, 1) 99%);
}

.submit {
  height: 50px;
  width: 200px;
  margin: 0.5em;
  color: #fff;
  font-size: 1em;
  font-weight: bolder;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 900px) {
  .wrapper {
    height: fit-content;
  }
  .pillar {
    width: 20%;
  }
  .rabbit {
    top: 69%;
    animation: moveXmobile 1s steps(6) infinite;
    width: 70px;
    background-size: cover;
    height: 75px;
  }
  .pillars > div:nth-child(2) > img:nth-child(2),
  .pillars > div:nth-child(6) > img:nth-child(2) {
    height: 90px;
  }

  .pillars > div:nth-child(4) > img:nth-child(2) {
    height: 150px;
  }

  .pillars > div:nth-child(1) > img:nth-child(2),
  .pillars > div:nth-child(3) > img:nth-child(2) {
    height: 80px;
  }
  .road {
    height: 30px;
  }
  .road img {
    height: 100%;
  }
  .inputArea {
    text-align: left;
  }

  .lightWrapper {
    display: none;
  }
}

@keyframes moveXmobile {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -443px;
  }
}

@media only screen and (max-width: 900px) {
  .dancing {
    bottom: 8.5%;
    animation: moveXmobile 1s steps(6) infinite;
    width: 70px;
    background-size: cover;
    height: 75px;
  }
}
