.gatherWrapper {
  overflow: hidden;
  background-image: url("../public/assets/images/bg/p2_bg_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
