.button {
  position: relative;
  text-align: center;
  background-image: url("../public/assets/images/button_02.png");
  background-size: 100% 100%;
  width: 250px;
  height: 60px;
  cursor: pointer;

}

.button h2 {
  color: #deac5e;
  display: block;
  margin: 0;
  transition: all .2s ease-in-out;
  font-size: 1.2em;
  font-weight: 900;
}

@keyframes heartBeat {
  0%,
  20%,
  50%,
  80%,
  100% {
    font-size: 1.2em;
  }
  40% {
    font-size: 1.8em;
  }
  60% {
    font-size: 1.4em;
  }
}


.button:hover h2 {
  color: #f1eae5;
  font-size: 1.5em;
  /* animation: heartBeat 1s infinite; */

}

.button a{
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #deac5e;
  text-decoration: none;
  width: 100%;
  height: 100%;
  z-index: 10;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .button {

  }
}
